export default theme => ({
  burger: {
    position: 'fixed',
    zIndex: 100,
    top: theme.spacing(4),
    right: theme.spacing(2),
    width: 30,
    height: 30,
  },
  menuOpen: {
    fill: `${theme.palette.white.main} !important`,
  },
});
